<template>
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 1.68231C8.88815 1.6008 8.75376 1.50712 8.59891 1.4066C8.11039 1.08948 7.40992 0.698811 6.56474 0.409741C4.86528 -0.171512 2.55108 -0.346228 0.251257 1.33933C0.0935794 1.45489 0 1.64147 0 1.84029V15.3844C0 15.615 0.125608 15.8262 0.325451 15.9318C0.525294 16.0373 0.765854 16.0194 0.948743 15.8853C2.87297 14.4751 4.78338 14.474 6.23603 14.8289C6.96654 15.0074 7.57756 15.2759 8.00544 15.4999C8.21878 15.6116 8.38484 15.7113 8.49552 15.7816C8.55081 15.8167 8.59213 15.8444 8.61846 15.8624C8.63162 15.8714 8.64102 15.878 8.64653 15.882L8.65126 15.8853L8.65166 15.8856C8.6517 15.8856 8.65174 15.8857 8.65177 15.8857C8.65192 15.8858 8.65207 15.8859 8.65222 15.886C8.8606 16.0381 9.14008 16.038 9.34834 15.8856L9.34874 15.8853L9.35347 15.882C9.35898 15.878 9.36838 15.8714 9.38154 15.8624C9.40788 15.8444 9.44919 15.8167 9.50449 15.7816C9.61516 15.7113 9.78123 15.6116 9.99456 15.4999C10.4224 15.2759 11.0335 15.0074 11.764 14.8289C13.2166 14.474 15.127 14.4751 17.0513 15.8853C17.2341 16.0194 17.4747 16.0373 17.6746 15.9318C17.8744 15.8262 18 15.615 18 15.3844V1.84029C18 1.64147 17.9064 1.45489 17.7487 1.33933C15.4489 -0.346228 13.1347 -0.171512 11.4353 0.409741C10.5901 0.698811 9.88961 1.08948 9.40109 1.4066C9.24624 1.50712 9.11185 1.6008 9 1.68231ZM9.6 14.326C10.0794 14.0864 10.7246 13.8172 11.486 13.6311C12.9473 13.2741 14.8475 13.2237 16.8 14.2809V2.16582C14.9856 0.961059 13.2013 1.1036 11.8147 1.57783C11.0849 1.82745 10.4729 2.16785 10.0427 2.44713C9.85856 2.56664 9.70928 2.67395 9.6 2.75653V14.326ZM8.4 14.326C7.92064 14.0864 7.27544 13.8172 6.51397 13.6311C5.05269 13.2741 3.15247 13.2237 1.2 14.2809V2.16582C3.01439 0.961059 4.79869 1.1036 6.18526 1.57783C6.91508 1.82745 7.52711 2.16785 7.95734 2.44713C8.14145 2.56664 8.29072 2.67395 8.4 2.75653V14.326Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
export default {
  props: {
    color: {type: String, required: false, default: '#FF6245'},
  },
};
</script>
